<template>
  <!-- HORIZONTAL LAYOUT -->
  <vx-card v-bind:title="this.title">
    <div class="vx-row mb-6" style="width: 50%">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
      >
        Back
      </vs-button>
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Team Name</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input
              class="w-full"
              v-model="header_data.name"
              name="Name"
              placeholder="Insert Team Name"
              v-validate="'required'"
            />
            <span class="text-danger text-sm" v-show="errors.has('Name')">
              {{ errors.first('Name') }}
            </span>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Territory Area</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedTerritoryArea"
              :options="optionTerritoryArea"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="200"
              :limit="3"
              :searchable="true"
              :custom-label="customLableTerritoryArea"
              placeholder="Type to search"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Territory</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedTerritory"
              :options="optionTerritory"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="200"
              :limit="3"
              placeholder="Type to search"
              :searchable="true"
              :custom-label="customLableTerritory"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Warehouse</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedWarehouse"
              :options="optionWarehouse"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="200"
              :limit="3"
              placeholder="Type to search"
              :searchable="true"
              :custom-label="customLableWarehouse"
            />
            <span class="text-danger text-sm" v-show="errors.has('Warehouse')">
              {{ errors.first('Warehouse') }}
            </span>
          </div>
        </div>
        <!-- <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <span>Code External</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full"> -->
        <vs-input
          v-model="header_data.external_code"
          name="Code External"
          type="hidden"
        />
        <!-- </div>
            </div> -->
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Team Leader</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-button size="small" class="mr-3 mb-2" @click="showModalLeader()"
              >Browse</vs-button
            >
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Team Members</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-button size="small" class="mr-3 mb-2" @click="showModal()"
              >Browse</vs-button
            >
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Open Tree View</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-button size="small" class="mr-3 mb-2" @click="showModalTreeview()"
              >Open</vs-button
            >
          </div>
        </div>
        <br />
        <hr />
        <br />
        <div class="vx-row mb-6">
          <div class="vx-col sm w-full">
            <span class="h4"> Work Team Leader </span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-1/12 text-center"><b>No.</b></div>
          <div class="vx-col sm:w-1/6 text-center"><b>Name</b></div>
          <div class="vx-col sm:w-1/6 text-center"><b>Work ID Number</b></div>
          <div class="vx-col sm:w-4/12 text-center"><b>Work Position</b></div>
          <div class="vx-col sm:w-2/12 text-center"><b>Code External</b></div>
          <div class="vx-col sm:w-1/12 text-center"><b>Action</b></div>
        </div>
        <br />
        <hr />
        <br />
        <div :key="i" v-for="(dt, i) in this.leader_data">
          <div class="vx-row">
            <div class="vx-col sm:w-1/12 text-center">
              {{ i + 1 }}
            </div>
            <div class="vx-col sm:w-1/6 text-center">
              {{ dt.name }}
            </div>
            <div class="vx-col sm:w-1/6 text-center">
              {{ dt.work_id_number }}
            </div>
            <div class="vx-col sm:w-4/12 text-center">
              <multiselect
                class="selectExample"
                v-model="dt.work_position"
                :options="optionWorkPosition"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                :max-height="150"
                :limit="3"
                placeholder="Type to search"
                :searchable="true"
                :custom-label="customLableWorkPosition"
              />
            </div>
            <div class="vx-col sm:w-2/12 text-center">
              {{ dt.code_external }}
            </div>
            <div class="vx-col sm:w-1/12 text-center">
              <feather-icon
                v-if="hasPermission('edit')"
                title="Delete"
                icon="TrashIcon"
                svgClasses="w-5 h-5 text-danger stroke-current"
                class="ml-2"
                @click="removeFromListLeader(i)"
              />
            </div>
          </div>
          <br />
          <hr />
          <br />
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm w-full">
            <span class="h4"> Work Team Members </span>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-1/12 text-center"><b>No.</b></div>
          <div class="vx-col sm:w-1/6 text-center"><b>Name</b></div>
          <div class="vx-col sm:w-1/6 text-center"><b>Work ID Number</b></div>
          <div class="vx-col sm:w-4/12 text-center"><b>Work Position</b></div>
          <div class="vx-col sm:w-2/12 text-center"><b>Code External</b></div>
          <div class="vx-col sm:w-1/12 text-center"><b>Action</b></div>
        </div>
        <br />
        <hr />
        <br />
        <div :key="i" v-for="(dt, i) in this.member_datas">
          <div class="vx-row">
            <div class="vx-col sm:w-1/12 text-center">
              {{ i + 1 }}
            </div>
            <div class="vx-col sm:w-1/6 text-center">
              {{ dt.name }}
            </div>
            <div class="vx-col sm:w-1/6 text-center">
              {{ dt.work_id_number }}
            </div>
            <div class="vx-col sm:w-4/12 text-center">
              <multiselect
                class="selectExample"
                v-model="dt.work_position"
                :options="optionWorkPosition"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                :max-height="150"
                :limit="3"
                placeholder="Type to search"
                :searchable="true"
                :custom-label="customLableWorkPosition"
              />
            </div>
            <div class="vx-col sm:w-2/12 text-center">
              {{ dt.code_external }}
            </div>
            <div class="vx-col sm:w-1/12 text-center">
              <feather-icon
                v-if="hasPermission('edit')"
                title="Delete"
                icon="TrashIcon"
                svgClasses="w-5 h-5 text-danger stroke-current"
                class="ml-2"
                @click="removeFromList(i)"
              />
            </div>
          </div>
          <br />
          <hr />
          <br />
        </div>
        <div class="vx-row text-left">
          <div class="vx-col sm w-full">
            <vs-button v-if="hasPermission('create')" class="mr-3 mb-2" v-on:click="SubmitForm()"
              >Submit</vs-button
            >
            <vs-button color="danger" class="mr-3 mb-2" @click="handleBack()"
              >Cancel</vs-button
            >
          </div>
        </div>
      </div>
      <!-- Right half col, datatable candidate -->
      <div v-if="modalEdit.activeModal" class="vx-col sm:w-1/2 w-full mb-base">
        <data-table
          v-if="!modalEdit.treeView"
          :baseUrl="`/api/sfa/v1/work-team`"
          :isLeader="this.modalEdit.isLeader"
          v-on:addToListDT="addToListDT"
        >
        </data-table>
        <tree-view
          v-else
          :source="'form'"
          :dataWorkTeam="this.dataWorkTeam"
        >
        </tree-view>
      </div>
    </div>
  </vx-card>
</template>

<script>
import DataTable from './DataTableCandidate.vue'
import TreeView from './Tree_view.vue'
export default {
  components: {
    DataTable,
    TreeView,
  },
  async mounted () {
    await this.$store.dispatch("user/getPermissions", "master-work-team");
    this.$vs.loading()

    await this.getWorkPosition()
    // If Update, so get data wt and set to UI
    if (this.$route.query.work_team_id && this.$route.query.company_id) {
      this.title = 'Update Work Team'
      this.work_team_id = this.$route.query.work_team_id
      this.company_id = this.$route.query.company_id
      this.update = true
      await this.getWorkTeam()
    }

    await this.getTerritoryArea()
    this.$vs.loading.close()
  },
  computed: {
    setPage: {
      get () {
        return 1
      },
      set (val) {
        this.handleChangePage(val)
      }
    }
  },
  data: () => ({
    modalEdit: {
      activeModal: false,
      isLeader: false,
      treeView: false
    },

    title: 'Create Work Team',
    work_team_id: null,
    company_id: null,
    update: false,

    header_data: {},
    member_datas: [],
    leader_data: [],
    member_personal_ids: [],
    leader_personal_ids: [],
    q_personal_id: '',

    optionTerritory: [],
    selectedTerritory: { id: null, code: null, name: null },

    optionTerritoryArea: [],
    selectedTerritoryArea: {
      code: null,
      name: null,
      city: null,
      district: null,
      sub_district: null
    },

    optionWarehouse: [],
    selectedWarehouse: { id: null, code: null, name: null },

    optionWorkPosition: [],
    selectedWorkPosition: {},

    baseUrl: '/api/sfa/v1/work-team/',
    detail: false,

    candidates: [],
    dataWorkTeam: {
      team_name : null,
      members: []
    },
  }),
  watch: {
    selectedTerritoryArea: function () {
      this.optionTerritory = []
      this.getTerritory(this.selectedTerritoryArea.id)
      this.selectedTerritory = { id: null, code: null, name: null }
      this.selectedWarehouse = { id: null, code: null, name: null }
    },
    selectedTerritory: function () {
      this.optionWarehouse = []
      this.getWarehouse(this.selectedTerritory.id)
      this.selectedWarehouse = { id: null, code: null, name: null }
    }
  },
  methods: {
    handleBack () {
      this.$router.push({
        name: 'work-team'
      })
    },
    showModal () {
      this.modalEdit.activeModal = true
      this.modalEdit.isLeader = false
      this.modalEdit.treeView = false
      this.scrollToTop();
    },
    showModalLeader () {
      this.modalEdit.activeModal = true
      this.modalEdit.isLeader = true
      this.modalEdit.treeView = false
      this.scrollToTop();
    },
    showModalTreeview(){
      var is_complete = true;
      this.dataWorkTeam.members.forEach(v => {
        if(v.work_position.parent_name == undefined || v.work_position.parent_name == null){
          is_complete = false;
        }
      });

      console.log(is_complete)

      if(this.dataWorkTeam.members.length > 0){
        if (is_complete) {
          console.log("showModalTreeview")
          this.dataWorkTeam.team_name = this.header_data.name
          this.modalEdit.activeModal = true
          this.modalEdit.isLeader = false
          this.modalEdit.treeView = true
          this.scrollToTop();
        } else {
          this.$vs.notify({
            title: 'Warning',
            text: 'Please select work position first !',
            color: 'danger',
            position: 'top-right',
            iconPack: 'feather',
            icon: 'icon-alert-circle'
          })
        }
      } else {
        this.$vs.notify({
          title: 'Warning',
          text: 'Please select leader or member first !',
          color: 'danger',
          position: 'top-right',
          iconPack: 'feather',
          icon: 'icon-alert-circle'
        })
      }

    },
    addToListDT (tr) {
      this.dataWorkTeam.members = [];
      if(this.modalEdit.isLeader){
        if(this.leader_data.length == 1){
          this.$vs.notify({
            title: 'Warning',
            text: 'Leader already selected !',
            color: 'danger',
            position: 'top-right',
            iconPack: 'feather',
            icon: 'icon-alert-circle'
          })
        }else{
          let isMember = false
          this.member_datas.forEach(element => {
            if (element.personal_id === tr.id) {
              isMember = true
              return false
            }
          })
          if (!isMember) {
            let leader = {
              code_external: tr.code_external,
              email: tr.email,
              id: null,
              mobile: tr.mobile,
              name: tr.name,
              personal_id: tr.id,
              work_id_number: tr.work_id_number,
              work_position: { id: null, name: null },
              work_position_id: null,
              work_team_id: this.$route.query.work_team_id,
              is_leader: true,
            }
            this.leader_data.push(leader)
            this.header_data.name = "Team " + tr.name;
            this.$vs.notify({
              title: 'Success',
              text: 'Leader selected !',
              color: 'success',
              position: 'top-right',
              iconPack: 'feather',
              icon: 'icon-check'
            })
          }else{
            this.$vs.notify({
              title: 'Warning',
              text: 'Candidate already added as a member !',
              color: 'danger',
              position: 'top-right',
              iconPack: 'feather',
              icon: 'icon-alert-circle'
            })
          }
        }
      }else{
        let isLeader = false;
        if (this.leader_data.length > 0) {
          isLeader = this.leader_data[0].personal_id === tr.id ? true : false;
        }

        if (isLeader) {
          this.$vs.notify({
              title: 'Warning',
              text: 'Candidate already selected as a leader !',
              color: 'danger',
              position: 'top-right',
              iconPack: 'feather',
              icon: 'icon-alert-circle'
          })
          return;
        }

        let isNewMember = true
        this.member_datas.forEach(element => {
          if (element.personal_id === tr.id) {
            isNewMember = false
            return false
          }
        })

        if (isNewMember) {
          let newMember = {
            code_external: tr.code_external,
            email: tr.email,
            id: null,
            mobile: tr.mobile,
            name: tr.name,
            personal_id: tr.id,
            work_id_number: tr.work_id_number,
            work_position: { id: null, name: null },
            work_position_id: null,
            work_team_id: this.$route.query.work_team_id,
            is_leader: false,
          }
          this.member_datas.push(newMember)
          this.$vs.notify({
            title: 'Success',
            text: 'Member added !',
            color: 'success',
            position: 'top-right',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        } else {
          this.$vs.notify({
            title: 'Warning',
            text: 'Member already exist !',
            color: 'danger',
            position: 'top-right',
            iconPack: 'feather',
            icon: 'icon-alert-circle'
          })
        }
      }

      // isi ulang dataWorkTeam
      if (this.leader_data.length > 0) {
        for (let i = 0; i < this.leader_data.length; i++) {
          this.dataWorkTeam.members.push(this.leader_data[i]);
        }
      }
      if (this.member_datas.length > 0) {
        for (let i = 0; i < this.member_datas.length; i++) {
          this.dataWorkTeam.members.push(this.member_datas[i]);
        }
      }
    },
    removeFromList (i) {
      this.member_datas.splice(i, 1)
      this.$vs.notify({
        title: 'Warning',
        text: 'Member removed !',
        color: 'danger',
        position: 'top-right',
        iconPack: 'feather',
        icon: 'icon-alert-circle'
      })

      // mengosongkan array
      this.dataWorkTeam.members = [];
      // isi ulang dataWorkTeam
      if (this.leader_data.length > 0) {
        for (let i = 0; i < this.leader_data.length; i++) {
          this.dataWorkTeam.members.push(this.leader_data[i]);
        }
      }
      if (this.member_datas.length > 0) {
        for (let i = 0; i < this.member_datas.length; i++) {
          this.dataWorkTeam.members.push(this.member_datas[i]);
        }
      }
    },
    removeFromListLeader (i) {
      this.leader_data.splice(i, 1)
      this.$vs.notify({
        title: 'Warning',
        text: 'Leader removed !',
        color: 'danger',
        position: 'top-right',
        iconPack: 'feather',
        icon: 'icon-alert-circle'
      })

      // mengosongkan array
      this.dataWorkTeam.members = [];
      // isi ulang dataWorkTeam
      if (this.leader_data.length > 0) {
        for (let i = 0; i < this.leader_data.length; i++) {
          this.dataWorkTeam.members.push(this.leader_data[i]);
        }
      }
      if (this.member_datas.length > 0) {
        for (let i = 0; i < this.member_datas.length; i++) {
          this.dataWorkTeam.members.push(this.member_datas[i]);
        }
      }
    },
    async getWorkTeam () {
      await this.$http
        .get('/api/sfa/v1/work-team/by-id', {
          params: {
            company_id: this.company_id,
            work_team_id: this.work_team_id
          }
        })
        .then(resp => {
          if (resp.code == 200) {
            this.header_data = resp.data.headers
            this.member_datas = resp.data.members
            this.leader_data = resp.data.leader
            this.dataWorkTeam.team_name = this.header_data.name

            if (resp.data.leader.length > 0) {
              for (let i = 0; i < this.leader_data.length; i++) {
                this.leader_data[i].is_leader = true;
                let wpid = this.leader_data[i].work_position_id
                this.optionWorkPosition.forEach(wp => {
                  if (wpid == wp.id) {
                    this.leader_data[i].work_position = wp
                  }
                })
                this.dataWorkTeam.members.push(this.leader_data[i]);
              }
            }

            if (resp.data.members.length > 0) {
              for (let i = 0; i < this.member_datas.length; i++) {
                this.member_datas[i].is_leader = false;
                let wpid = this.member_datas[i].work_position_id
                this.optionWorkPosition.forEach(wp => {
                  if (wpid == wp.id) {
                    this.member_datas[i].work_position = wp
                  }
                })
                this.dataWorkTeam.members.push(this.member_datas[i]);
              }
            }

          } else {
            // console.log(resp)
            this.$vs.notify({
              title: 'Error',
              text: resp.message,
              color: 'danger',
              position: 'top-right',
              iconPack: 'feather',
              icon: 'icon-x-circle'
            })
          }
        })
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    SubmitForm () {
      // console.log("submit data", {
      //           header_data: this.header_data,
      //           leader_data: this.leader_data,
      //           member_data: this.member_datas
      //         })
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$vs.loading()
          // Persiapan cek member & leader
          if (this.member_datas.length > 0 && this.leader_data.length > 0) {
            this.member_personal_ids = []
            for (let i = 0; i < this.member_datas.length; i++) {
              this.member_personal_ids.push(this.member_datas[i].personal_id)
            }
            this.leader_personal_ids.push(this.leader_data[0].personal_id)

            let personal_ids = this.member_personal_ids.concat(this.leader_personal_ids);

            this.q_personal_id = personal_ids.join(', ')
          } else {
            this.q_personal_id = ''
          }

          this.header_data.territory_area_id = this.selectedTerritoryArea.id
          this.header_data.territory_id = this.selectedTerritory.id
          this.header_data.warehouse_id = this.selectedWarehouse.id
          this.header_data.company_id = this.$store.state.user.currentUser.company_id
          this.header_data.memberPID = this.q_personal_id

          // console.log("Header Data", this.header_data)
          // console.log("Leader Data", this.leader_data)
          // console.log("Members Data", this.member_datas)

          this.$vs.loading.close()
          if (!this.update) {
            // If create
            this.header_data.id = 0
            this.$http
              .post('/api/sfa/v1/work-team/store', {
                header_data: this.header_data,
                leader_data: this.leader_data,
                member_data: this.member_datas
              })
              .then(resp => {
                this.$vs.loading.close()
                if (resp.code == 200) {
                  this.$vs.loading.close()
                  this.$vs.notify({
                    title: 'Success',
                    text: 'Create work team success',
                    color: 'success',
                    position: 'top-right',
                    iconPack: 'feather',
                    icon: 'icon-check'
                  })
                  this.handleBack()
                } else {
                  // Notifikasi member / leader exist in another team, diremark karena belum butuh notifikasi "member ada di team lain"
                  // if (resp.data) {
                  //   resp.data.forEach(exist => {
                  //     this.$vs.notify({
                  //       title: 'Error',
                  //       time: 3000,
                  //       text:
                  //         resp.message +
                  //         '<br>' +
                  //         exist.name +
                  //         ' is in <b>' +
                  //         exist.work_team_name +
                  //         '</b>',
                  //       color: 'danger',
                  //       position: 'top-right',
                  //       iconPack: 'feather',
                  //       icon: 'icon-x-circle'
                  //     })
                  //   })
                  // } else {
                    this.$vs.notify({
                      title: 'Error',
                      text: resp.message,
                      color: 'danger',
                      position: 'top-right',
                      iconPack: 'feather',
                      icon: 'icon-x-circle'
                    })
                  // }
                }
              })
          } else {
            // If update
            this.$http
              .put('/api/sfa/v1/work-team/update', {
                header_data: this.header_data,
                leader_data: this.leader_data,
                member_data: this.member_datas
              })
              .then(resp => {
                this.$vs.loading.close()
                if (resp.code == 200) {
                  this.$vs.notify({
                    title: 'Success',
                    text: 'Update work team success',
                    color: 'success',
                    position: 'top-right',
                    iconPack: 'feather',
                    icon: 'icon-check'
                  })
                  this.handleBack()
                } else {
                  if (resp.data != null) {
                    resp.data.forEach(exist => {
                      this.$vs.notify({
                        title: 'Error',
                        time: 3000,
                        text:
                          resp.message +
                          '<br>' +
                          exist.name +
                          ' is in <b>' +
                          exist.work_team_name +
                          '</b>',
                        // text: resp.message + "\n" + exist.name + " is on " + exist.work_team_name,
                        color: 'danger',
                        position: 'top-right',
                        iconPack: 'feather',
                        icon: 'icon-x-circle'
                      })
                    })
                  } else {
                    this.$vs.notify({
                      title: 'Error',
                      text: resp.message,
                      color: 'danger',
                      position: 'top-right',
                      iconPack: 'feather',
                      icon: 'icon-x-circle'
                    })
                  }
                }
              })
          }
        }
      })
    },
    async getTerritoryArea () {
      await this.$http
        .get('/api/sfa/v1/work-team/territory-area')
        .then(resp => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritoryArea = resp.data.records
              if (this.update) {
                this.optionTerritoryArea.forEach(ta => {
                  if (ta.id == this.header_data.territory_area_id) {
                    this.selectedTerritoryArea = ta
                  }
                })
              }
            }
          } else {
            // console.log(resp)
            this.$vs.notify({
              title: 'Error',
              text: resp.message,
              color: 'danger',
              position: 'top-right',
              iconPack: 'feather',
              icon: 'icon-x-circle'
            })
            this.$vs.loading.close()
          }
        })
    },
    async getTerritory (ta_id = -1) {
      await this.$http
        .get('/api/sfa/v1/work-team/territory', {
          params: {
            territory_area_id: ta_id
          }
        })
        .then(resp => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = resp.data.records
              if (this.update) {
                this.optionTerritory.forEach(t => {
                  if (t.id == this.header_data.territory_id) {
                    this.selectedTerritory = t
                  }
                })
              }
            }
          } else {
            // console.log(resp)
            this.$vs.loading.close()
          }
        })
    },
    async getWarehouse (t_id = -1) {
      if (t_id != null) {
        await this.$http
          .get('/api/sfa/v1/work-team/warehouse', {
            params: {
              territory_id: t_id
            }
          })
          .then(resp => {
            if (resp.code == 200) {
              if (resp.data.records) {
                this.optionWarehouse = resp.data.records
                if (this.update) {
                  this.optionWarehouse.forEach(w => {
                    if (w.id == this.header_data.warehouse_id) {
                      this.selectedWarehouse = w
                    }
                  })
                }
              }
            } else {
              // console.log(resp)
              this.$vs.notify({
                title: 'Error',
                text: resp.message,
                color: 'danger',
                position: 'top-right',
                iconPack: 'feather',
                icon: 'icon-x-circle'
              })
              this.$vs.loading.close()
            }
          })
      }
    },
    async getWorkPosition () {
      await this.$http.get('/api/sfa/v1/work-team/work-position').then(resp => {
        // console.log(resp);
        if (resp.code == 200) {
          if (resp.data.records) {
            this.optionWorkPosition = resp.data.records
            this.selectedWorkPosition = {
              id: null,
              name: null,
              parent_name: null
            }
          }
        } else {
          // console.log(resp)
          this.$vs.notify({
            title: 'Error',
            text: resp.message,
            color: 'danger',
            position: 'top-right',
            iconPack: 'feather',
            icon: 'icon-x-circle'
          })
          this.$vs.loading.close()
        }
      })
    },
    customLableTerritoryArea ({ code, name, city, district, sub_district }) {
      if (
        name != '' &&
        city != '' &&
        district != '' &&
        sub_district != '' &&
        name != null &&
        city != null &&
        district != null &&
        sub_district != null
      ) {
        return `${name} - ${city} - ${district} - ${sub_district}`
      } else if (
        name == null &&
        city == null &&
        district == null &&
        sub_district == null
      ) {
        return `Select Territory Area`
      } else {
        return `Code ${code} | Name ${name} | City ${city} | District ${district} | Subdistrict ${sub_district}`
      }
    },
    customLableTerritory ({ id, code, name }) {
      if (name != '' && code != '' && name != null && code != null) {
        return `${code} - ${name}`
      } else if (id == null && name == null && code == null) {
        return `Select Territory`
      } else {
        return `ID ${id} | Code ${code} | Name ${name}`
      }
    },
    customLableWarehouse ({ id, name, code }) {
      if (name != '' && code != '' && name != null && code != null) {
        return `${code} - ${name}`
      } else if (id == null && name == null && code == null) {
        return `Select Warehouse`
      } else {
        return `ID ${id} | Code ${code} | Name ${name}`
      }
    },
    customLableWorkPosition ({ id, name, parent_name, level }) {
      if (name != '' && name != null) {
        return `${level} - [${parent_name}] - ${name}`
      } else if (id == null && name == null && parent_name == null) {
        return `Select Work Position`
      } else {
        return `ID : ${id} | ${name}`
      }
    },
  }
}
</script>
